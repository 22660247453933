<template>
  <div style="background: white">
    <!-- <HeaderBar /> -->
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_home" />
      <v-container fluid>
        <v-row>
          <!-- 共通お知らせ-->
          <v-col cols="1" />
          <v-col cols="5">
            <v-toolbar-title>{{ $t("label.lbl_noticeAll") }}</v-toolbar-title>
            <v-data-table
              id="time-table"
              fixed-header
              :headers="headerItems"
              :items="everyList"
              :page.sync="pageEvery"
              :server-items-length="limitPage"
              disable-filtering
              disable-sort
              :hide-default-footer="true"
              height="550px"
              @page-count="pageCountEvery = $event"
              @click:row="showDetail"
            >
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination
                v-model="pageEvery"
                :length="pageCountEvery"
                :total-visible="7"
              ></v-pagination>
            </div>
          </v-col>
          <!-- 個別お知らせ-->
          <v-col cols="5">
            <v-toolbar-title>{{ $t("label.lbl_noticeUnit") }}</v-toolbar-title>
            <v-data-table
              id="time-table"
              fixed-header
              :headers="headerItems"
              :items="unitList"
              :page.sync="pageUnit"
              :server-items-length="limitPage"
              disable-filtering
              disable-sort
              :hide-default-footer="true"
              height="550px"
              @page-count="pageCountUnit = $event"
              @click:row="showDetail"
            >
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination v-model="pageUnit" :length="pageCountUnit"></v-pagination>
            </div>
          </v-col>
          <v-col cols="1">
            <v-btn
              class="get-btn"
              style="float: left"
              @click="moveIniMasterReg()"
              v-show="iniFlg"
              >{{ $t("label.lbl_iniMasterRegistrationStatus") }}</v-btn
            >
          </v-col>
        </v-row>
        <!-- お知らせ詳細ダイアログ-->
        <v-dialog v-model="dialogNotice" max-width="800">
          <v-card>
            <v-card-title class="blue-grey lighten-3" primary-title>
              {{ $t("label.lbl_noticeDetail") }}
            </v-card-title>
            <v-card-text>
              <v-container cols="6">
                <v-row>
                  <v-col xl="2">
                    <span>
                      <strong>{{ $t("label.lbl_noticeTitle") }}</strong>
                    </span>
                  </v-col>
                  <v-col xl="10">
                    {{ noticeTitle }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xl="2">
                    <span>
                      <strong>{{ $t("label.lbl_noticeDate") }}</strong>
                    </span>
                  </v-col>
                  <v-col xl="5">
                    {{ noticeDate }}
                  </v-col>
                </v-row>
                <v-row rows="5">
                  <v-col xl="2">
                    <span>
                      <strong>{{ $t("label.lbl_noticeDetail") }}</strong>
                    </span>
                  </v-col>
                  <v-col xl="10">
                    {{ noticeDetail }}
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn v-if="componentId" @click="movePage(componentId)">
                {{ $t("btn.btn_list") }}
              </v-btn>
              <v-btn @click="noticeOk">
                {{ $t("btn.btn_close") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
    </v-sheet>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { i18n } from "../../lang/lang.js";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";

export default {
  name: appConfig.MENU_ID.M_HOM,
  components: {
    // HeaderBar,
    Loading,
    sideMenu,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    SYSTEM: "system",
    // メニュー
    openMenu: null,
    // お知らせダイアログ
    dialogNotice: false,
    // お知らせダイアログ表題
    noticeTitle: "",
    // お知らせダイアログ日付
    noticeDate: "",
    // お知らせダイアログ詳細
    noticeDetail: "",
    // componentId
    componentId: "",
    // 個別お知らせ一覧ページング
    pageUnit: 1,
    pageCountUnit: 0,
    limitPage: 10,
    // 共通お知らせ一覧ページング
    pageEvery: 1,
    pageCountEvery: 0,
    iniFlg: "false", // 初期導入フラグ
    paramClientCd: "", // 引継ぎ用取引先コード
    // お知らせ一覧ヘッダ
    headerItems: [
      {
        text: i18n.tc("label.lbl_noticeTitle"),
        value: "noticeTitle",
        width: "70%",
        align: "left",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_noticeDate"),
        value: "noticeDate",
        width: "30%",
        align: "center",
        sortable: false,
      },
    ],
    // 共通お知らせ一覧
    everyList: [],
    // 個別お知らせ一覧
    unitList: [],
  }),
  methods: {
    init() {
      this.getNotice(this.SYSTEM, this.pageEvery);
      this.getNotice(sessionStorage.getItem("usercode"), this.pageUnit);
    },
    getNotice(userId, pageIdx) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // this.$set(this.inputList, "", "");
      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();
      // 営業所SID
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      // ユーザーIDuserId
      config.params.userId = userId;
      // ページャー処理
      config.params.reqComPageIndex = pageIdx;
      // ページング
      config.params.reqComPaginationFlg = "1";
      config.params.reqComPageLimit = this.limitPage;
      // 接続先のAPI_URLを入力
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_NOTICES, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              if (this.SYSTEM == userId) {
                this.pageCountEvery = jsonData.resCom.resComPagination.totalPage;
              } else {
                this.pageCountUnit = jsonData.resCom.resComPagination.totalPage;
              }
              let data = [];
              // for(){}繰り返し処理を行い、配列を格納する処理を記述する
              for (let i = 0; i < jsonData.resIdv.notices.length; i++) {
                data.push({
                  noticeTitle: jsonData.resIdv.notices[i].noticeTitle,
                  noticeDate: dateTimeHelper
                    .convertUTC2JST(jsonData.resIdv.notices[i].createDatetime)
                    .substr(0, 16),
                  noticeDetail: jsonData.resIdv.notices[i].noticeContents,
                  componentId: jsonData.resIdv.notices[i].componentId,
                });
                if (this.SYSTEM == userId) {
                  this.everyList = data;
                } else {
                  this.unitList = data;
                }
              }
              resolve();
              // エラー時
            } else {
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch(() => {
            // console.debug("getNotice(userId, pageIdx) Resolve", resolve);
            reject(messsageUtil.getMessage("P-999-999_999_E"));
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    movePage(componentId) {
      this.$router.push({
        name: componentId,
      });
    },

    // マスタ登録状況ボタン押下
    moveIniMasterReg() {
      this.$router.push({
        name: appConfig.MENU_ID.M_INI_MASTER_REGIST,
        params: {
          // 取引先コード
          clientCd: this.paramClientCd,
        },
      });
    },

    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },
    // お知らせダイアログより確認ボタン
    noticeOk() {
      this.dialogNotice = false;
    },
    // お知らせ詳細ダイアログ
    showDetail(data) {
      this.noticeDate = data.noticeDate;
      this.noticeTitle = data.noticeTitle;
      this.noticeDetail = data.noticeDetail;
      this.componentId = data.componentId;
      this.dialogNotice = true;
    },
  },
  computed: {},
  watch: {
    pageEvery: function (newValue) {
      this.getNotice(this.SYSTEM, newValue);
    },
    pageUnit: function (newValue) {
      this.getNotice(sessionStorage.getItem("usercode"), newValue);
    },
  },
  mounted() {
    this.paramClientCd = this.$route.params.clientCd; // 取引先コード(引継ぎ用)
    this.iniFlg = this.$route.params.iniFlg; // 初期導入フラグ
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
#sub-bar {
  color: black;
  background: $color-obj-base;
  padding: 0;
  height: 3rem;
  #lbl-screen-name {
    padding: 0.1rem 0 0 0.5rem;
    font-size: x-large;
    font-weight: bold;
    display: inline-block;
  }
  .right {
    text-align: right;
  }
}
.v-btn.v-btn--has-bg {
  background: $color-obj-base;
  &.api-btn {
    width: 7rem;
  }
}
#display-type {
  width: 25rem;
  height: 5.5rem;
  .v-card__title {
    font-size: 1rem;
    padding: 0 0.2rem;
  }
  .toggle {
    padding: 0.3rem 2rem;
    .btn {
      width: 5rem;
      height: 2.5rem;
    }
  }
}
#table-guide {
  margin-top: 4.5rem;
  font-size: middle;
  #page-count-guide {
    width: 100%;
    text-align: center;
  }
  .guide-space {
    padding: 0;
  }
  #guide-color {
    margin-top: 1rem;
    .guide-warning,
    .guide-error {
      display: inline-block;
      width: 6rem;
      text-align: center;
      border: solid 1px;
      margin: 0.2rem 0.5rem;
    }
    .guide-warning {
      background: $color-warning;
    }
    .guide-error {
      background: $color-error;
    }
  }
}
.search-label {
  width: 100%;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0;
  text-align: right;
  font-size: large;
  color: rgba(0, 0, 0, 0.6);
}
.txt-single ::v-deep {
  padding-top: 0;
  padding-right: 0;
}

#footer-navi {
  margin-top: 0;
}

button {
  &.v-btn {
    &.day-ctrl-btn {
      padding: 0;
      width: 2rem;
      min-width: 2.5rem;
    }
    &.day-ctrl-now-btn {
      padding: 0;
      width: 4rem;
      min-width: 4rem;
    }
  }
}
.txt-single ::v-deep {
  padding-top: 0;
  padding-right: 0;
  font-size: large;
}
.row .col-xl-10 {
  word-break: break-all;
}
</style>
